import Vue from 'vue'
import Vuex from 'vuex'

import * as auth from '@/store/modules/Auth'
import * as user from '@/store/modules/User'
import * as matiere from '@/store/modules/Matiere'
import * as etablissement from '@/store/modules/Etablissement'
import * as concour from '@/store/modules/Concour'
import * as poste from '@/store/modules/Poste'
import * as documenttype from '@/store/modules/DocumentType'
import * as document from '@/store/modules/Document'
import * as dossieracademique from '@/store/modules/DossierAcademique'
import * as session from '@/store/modules/Session'
import * as epreuve from '@/store/modules/Epreuve'
import * as pv from '@/store/modules/Pv'
import * as dossieradministratif from '@/store/modules/DossierAdministratif'
import * as dossierinscription from '@/store/modules/DossierInscription'
import * as contrattype from '@/store/modules/ContratType'
import * as baremeremuneration from '@/store/modules/BaremeRemuneration'
import * as notificationtemplate from '@/store/modules/NotificationTemplate'
import * as rgpdentity from '@/store/modules/RgpdEntity'
import * as documentsignable from '@/store/modules/DocumentSignable'
import * as ordremission from '@/store/modules/OrdreMission'
import * as demanderemboursement from '@/store/modules/DemandeRemboursement'
import * as ville from '@/store/modules/Ville'
import * as centre from '@/store/modules/Centre'
import * as adresseCentre from '@/store/modules/AdresseCentre'
import * as conventiontype from '@/store/modules/ConventionType'
import * as candidat from '@/store/modules/Candidat'
import * as comptecandidat from '@/store/modules/CompteCandidat'
import * as amenagement from '@/store/modules/Amenagement'
import * as salle from '@/store/modules/Salle'
import * as ensemble from '@/store/modules/Ensemble'
import * as ensembleAffectationsExaminateurs from '@/store/modules/EnsembleAffectationsExaminateurs'
import * as serie from '@/store/modules/Serie'
import * as affectationExaminateur from '@/store/modules/AffectationExaminateur'
import * as organisationecrit from '@/store/modules/OrganisationEcrit'
import * as intervenant from '@/store/modules/Intervenant'
import * as gabaritFicheHoraire from '@/store/modules/GabaritFicheHoraire'
import * as examinateur from '@/store/modules/Examinateur'
import * as candidatIncompatible from '@/store/modules/CandidatIncompatible'
import * as candidatIncompPubliDesSaisies from '@/store/modules/CandidatIncompPubliDesSaisies'
import * as pays from '@/store/modules/Pays'
import * as banque from '@/store/modules/Banque'
import * as affectationEquipe from '@/store/modules/AffectationEquipe'
import * as disponibilite from '@/store/modules/Disponibilites'
import * as sessionuser from '@/store/modules/SessionUser'
import * as dashboardOraux from '@/store/modules/DashboardOraux'
import * as epreuveCorrectionResultat from '@/store/modules/EpreuveCorrectionResultat'
import * as candidatEpreuve from '@/store/modules/CandidatEpreuve'
import * as definitionDesBarres from '@/store/modules/DefinitionDesBarres'
import * as concourPhase from '@/store/modules/ConcourPhase'
import * as definitionBarresListeNotes from '@/store/modules/DefinitionBarresListeNotes'
import * as definitionBarresListeNotesAdmission from '@/store/modules/DefinitionBarresListeNotesAdmission'
import * as planification from '@/store/modules/Planification'
import * as definitionBarresStatistiquesEpreuves from '@/store/modules/DefinitionBarresStatistiquesEpreuves'
import * as definitionBarresStatistiquesConcours from '@/store/modules/DefinitionBarresStatistiquesConcours'
import * as definitionBarresAnalysesHypotheses from '@/store/modules/DefinitionBarresAnalyseHypothese'
import * as dashboardConcours from '@/store/modules/DashboardConcours'
import * as publishableMessage from '@/store/modules/PublishableMessage'
import * as publication from '@/store/modules/Publication'
import * as demission from '@/store/modules/Demission'
import * as echartsToolsManage from '@/store/modules/EchartsToolsManage'
import * as impressionExaminateur from '@/store/modules/ImpressionExaminateur'
import * as impressionCentre from '@/store/modules/ImpressionCentre'
import * as planning from '@/store/modules/Planning'
import * as impressionCandidat from '@/store/modules/ImpressionCandidat'
import * as reclamation from '@/store/modules/Reclamation'
import * as suppleant from '@/store/modules/Suppleant'
import * as reclamationPostConcours from '@/store/modules/ReclamationPostConcours'
import * as listeExaminateurCdc from '@/store/modules/ListeExaminateurCdc'
import * as reclamationOral from '@/store/modules/ReclamationOral'
import * as reclassement from '@/store/modules/Reclassement'
import * as decisionjury from '@/store/modules/DecisionJury'
import * as decisionamenagement from '@/store/modules/DecisionAmenagement'
import * as listeCandidatCdc from '@/store/modules/ListeCandidatCdc'
import * as presenceExaminateur from '@/store/modules/PresenceExaminateur'
import * as configPhase from '@/store/modules/ConfigPhase'
import * as ajustement from '@/store/modules/Ajustement'
import * as bonusCandidat from '@/store/modules/BonusCandidat'
import * as phase from '@/store/modules/Phase'
import * as avenanttype from '@/store/modules/AvenantType'
import * as avenant from '@/store/modules/Avenant'
import * as repartitioncandidats from '@/store/modules/RepartitionCandidats'
import * as tempsSupplementaire from '@/store/modules/TempsSupplementaire'
import * as domaine from '@/store/modules/Domaine'
import * as gestionParticuliere from '@/store/modules/GestionParticuliere'
import * as dashboardEcrits from '@/store/modules/DashboardEcrits'
import * as couleur from '@/store/modules/Couleur'
import * as sujet from '@/store/modules/Sujet'
import * as enveloppe from '@/store/modules/Enveloppe'
import * as centresujet from '@/store/modules/CentreSujet'
import * as fourniture from '@/store/modules/Fourniture'
import * as centrefp from '@/store/modules/CentreFourniturePapetiere'
import * as centrefa from '@/store/modules/CentreFournitureAdministrative'
import * as fournitureAdministrative from '@/store/modules/FournitureAdministrative'
import * as affectationCorrecteur from '@/store/modules/AffectationCorrecteur'
import * as affectationResponsable from '@/store/modules/AffectationResponsable'
import * as groupeIntervenant from '@/store/modules/GroupeIntervenant'
import * as groupeExaminateur from '@/store/modules/GroupeExaminateur'
import * as groupeExaminateurUser from '@/store/modules/GroupeExaminateurUser'
import * as reclamationType from '@/store/modules/ReclamationType'
import * as seanceAnomalie from '@/store/modules/SeanceAnomalie'
import * as deontologies from '@/store/modules/Deontologies'
import * as notificationTemplatePortail from '@/store/modules/NotificationTemplatePortail'
import * as candidatConcourPhaseListe from '@/store/modules/CandidatConcourPhaselListe'
import * as liste from '@/store/modules/Liste'
import * as posteOuvert from '@/store/modules/PosteOuvert'
import * as historiqueNotification from '@/store/modules/HistoriqueNotification'
import * as formulaire from '@/store/modules/Formulaire'
import * as tournee from '@/store/modules/Tournee'
import * as conditionnement from '@/store/modules/Conditionnement'
import * as rankingGroup from '@/store/modules/RankingGroup'
import * as parameter from '@/store/modules/Parameter'
import * as documentation from '@/store/modules/Documentation'
import * as groupeComparaisonConcour from '@/store/modules/GroupeComparaisonConcour'
import * as jobDescription from '@/store/modules/JobDescription'
import * as workingEstablishment from '@/store/modules/workingEstablishment'
import * as jobProfileForm from '@/store/modules/JobProfileForm'
import * as candidatJobDescription from '@/store/modules/CandidatJobDescription'
import * as candidatJob from '@/store/modules/CandidatJob'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: false,
    actions: {
        reset(context) {
            context.commit('auth/RESET_STATE')
            context.commit('user/RESET_STATE')
            context.commit('matiere/RESET_STATE')
            context.commit('etablissement/RESET_STATE')
            context.commit('concour/RESET_STATE')
            context.commit('poste/RESET_STATE')
            context.commit('documenttype/RESET_STATE')
            context.commit('document/RESET_STATE')
            context.commit('dossieracademique/RESET_STATE')
            context.commit('session/RESET_STATE')
            context.commit('epreuve/RESET_STATE')
            context.commit('pv/RESET_STATE')
            context.commit('dossieradministratif/RESET_STATE')
            context.commit('dossierinscription/RESET_STATE')
            context.commit('contrattype/RESET_STATE')
            context.commit('baremeremuneration/RESET_STATE')
            context.commit('documentsignable/RESET_STATE')
            context.commit('ordremission/RESET_STATE')
            context.commit('demanderemboursement/RESET_STATE')
            context.commit('ville/RESET_STATE')
            context.commit('centre/RESET_STATE')
            context.commit('adresseCentre/RESET_STATE')
            context.commit('conventiontype/RESET_STATE')
            context.commit('candidat/RESET_STATE')
            context.commit('comptecandidat/RESET_STATE')
            context.commit('examinateur/RESET_STATE')
            context.commit('salle/RESET_STATE')
            context.commit('ensemble/RESET_STATE')
            context.commit('ensembleAffectationsExaminateurs/RESET_STATE')
            context.commit('serie/RESET_STATE')
            context.commit('affectationExaminateur/RESET_STATE')
            context.commit('organisationecrit/RESET_STATE')
            context.commit('intervenant/RESET_STATE')
            context.commit('gabaritFicheHoraire/RESET_STATE')
            context.commit('pays/RESET_STATE')
            context.commit('candidatIncompPubliDesSaisies/RESET_STATE')
            context.commit('banque/RESET_STATE')
            context.commit('disponibilite/RESET_STATE')
            context.commit('sessionuser/RESET_STATE')
            context.commit('epreuveCorrectionResultat/RESET_STATE')
            context.commit('candidatEpreuve/RESET_STATE')
            context.commit('definitionDesBarres/RESET_STATE')
            context.commit('concourPhase/RESET_STATE')
            context.commit('definitionBarresListeNotes/RESET_STATE')
            context.commit('definitionBarresListeNotesAdmission/RESET_STATE')
            context.commit('planification/RESET_STATE')
            context.commit('publishableMessage/RESET_STATE')
            context.commit('publication/RESET_STATE')
            context.commit('reclamation/RESET_STATE')
            context.commit('demission/RESET_STATE')
            context.commit('echartsToolsManage/RESET_STATE')
            context.commit('impressionExaminateur/RESET_STATE')
            context.commit('impressionCentre/RESET_STATE')
            context.commit('planning/RESET_STATE')
            context.commit('impressionCandidat/RESET_STATE')
            context.commit('suppleant/RESET_STATE')
            context.commit('reclamationPostConcours/RESET_STATE')
            context.commit('listeExaminateurCdc/RESET_STATE')
            context.commit('reclamationOral/RESET_STATE')
            context.commit('decisionjury/RESET_STATE')
            context.commit('decisionamenagement/RESET_STATE')
            context.commit('listeCandidatCdc/RESET_STATE')
            context.commit('presenceExaminateur/RESET_STATE')
            context.commit('configPhase/RESET_STATE')
            context.commit('ajustement/RESET_STATE')
            context.commit('bonusCandidat/RESET_STATE')
            context.commit('phase/RESET_STATE')
            context.commit('avenanttype/RESET_STATE')
            context.commit('avenant/RESET_STATE')
            context.commit('repartitioncandidats/RESET_STATE')
            context.commit('tempsSupplementaire/RESET_STATE')
            context.commit('domaine/RESET_STATE')
            context.commit('gestionParticuliere/RESET_STATE')
            context.commit('dashboardEcrits/RESET_STATE')
            context.commit('couleur/RESET_STATE')
            context.commit('sujet/RESET_STATE')
            context.commit('enveloppe/RESET_STATE')
            context.commit('fourniture/RESET_STATE')
            context.commit('centresujet/RESET_STATE')
            context.commit('centrefp/RESET_STATE')
            context.commit('centrefa/RESET_STATE')
            context.commit('fournitureAdministrative/RESET_STATE')
            context.commit('affectationCorrecteur/RESET_STATE')
            context.commit('affectationResponsable/RESET_STATE')
            context.commit('groupeIntervenant/RESET_STATE')
            context.commit('groupeExaminateur/RESET_STATE')
            context.commit('groupeExaminateurUser/RESET_STATE')
            context.commit('reclamationType/RESET_STATE')
            context.commit('seanceAnomalie/RESET_STATE')
            context.commit('deontologies/RESET_STATE')
            context.commit('notificationTemplatePortail/RESET_STATE')
            context.commit('candidatConcourPhaseListe/RESET_STATE')
            context.commit('liste/RESET_STATE')
            context.commit('posteOuvert/RESET_STATE')
            context.commit('historiqueNotification/RESET_STATE')
            context.commit('formulaire/RESET_STATE')
            context.commit('tournee/RESET_STATE')
            context.commit('conditionnement/RESET_STATE')
            context.commit('rankingGroup/RESET_STATE')
            context.commit('parameter/RESET_STATE')
            context.commit('documentation/RESET_STATE')
            context.commit('groupeComparaisonConcour/RESET_STATE')
            context.commit('jobDescription/RESET_STATE')
            context.commit('workingEstablishment/RESET_STATE')
            context.commit('jobProfileForm/RESET_STATE')
            context.commit('candidatJobDescription/RESET_STATE')
            context.commit('candidatJob/RESET_STATE')
        }
    },
    modules: {
        auth,
        user,
        matiere,
        etablissement,
        concour,
        poste,
        documenttype,
        document,
        dossieracademique,
        session,
        epreuve,
        pv,
        dossieradministratif,
        dossierinscription,
        contrattype,
        baremeremuneration,
        notificationtemplate,
        rgpdentity,
        documentsignable,
        ordremission,
        demanderemboursement,
        ville,
        centre,
        adresseCentre,
        conventiontype,
        salle,
        ensemble,
        ensembleAffectationsExaminateurs,
        amenagement,
        candidat,
        comptecandidat,
        serie,
        affectationExaminateur,
        organisationecrit,
        intervenant,
        gabaritFicheHoraire,
        examinateur,
        candidatIncompatible,
        candidatIncompPubliDesSaisies,
        pays,
        banque,
        affectationEquipe,
        disponibilite,
        sessionuser,
        dashboardOraux,
        epreuveCorrectionResultat,
        candidatEpreuve,
        definitionDesBarres,
        concourPhase,
        definitionBarresListeNotes,
        definitionBarresListeNotesAdmission,
        planification,
        definitionBarresStatistiquesEpreuves,
        definitionBarresStatistiquesConcours,
        definitionBarresAnalysesHypotheses,
        dashboardConcours,
        publishableMessage,
        publication,
        reclamation,
        demission,
        echartsToolsManage,
        impressionExaminateur,
        impressionCentre,
        planning,
        impressionCandidat,
        suppleant,
        reclamationPostConcours,
        listeExaminateurCdc,
        reclamationOral,
        reclassement,
        decisionjury,
        decisionamenagement,
        listeCandidatCdc,
        presenceExaminateur,
        configPhase,
        ajustement,
        bonusCandidat,
        phase,
        avenanttype,
        avenant,
        repartitioncandidats,
        tempsSupplementaire,
        domaine,
        gestionParticuliere,
        dashboardEcrits,
        couleur,
        sujet,
        enveloppe,
        fourniture,
        centresujet,
        centrefp,
        centrefa,
        fournitureAdministrative,
        affectationCorrecteur,
        affectationResponsable,
        groupeIntervenant,
        groupeExaminateur,
        groupeExaminateurUser,
        reclamationType,
        seanceAnomalie,
        deontologies,
        notificationTemplatePortail,
        candidatConcourPhaseListe,
        liste,
        posteOuvert,
        historiqueNotification,
        formulaire,
        tournee,
        conditionnement,
        rankingGroup,
        parameter,
        documentation,
        groupeComparaisonConcour,
        jobDescription,
        workingEstablishment,
        jobProfileForm,
        candidatJobDescription,
        candidatJob
    }
})

export default store
