import * as API from '@/services/API'
import store from '@/store'

export default {
    async getWorkingEstablishment(working_establishment_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/workingestablishments/${working_establishment_id}`)
    },
    async getWorkingEstablishments(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/workingestablishments`, { params: payload })
    },
    async updateWorkingEstablishment(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/workingestablishments/${payload.id}`, payload)
    },
    async addWorkingEstablishment(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/workingestablishments`, payload)
    },
    async deleteWorkingEstablishment(working_establishment_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/workingestablishments/${working_establishment_id}`)
    },
    async importWorkingEstablishment(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/workingestablishments/import`, formData) // , config)
    },
    async getWorkingEstablishmentsByAcademie(academie: string, type: string) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/workingestablishments?academie=${academie}&type=${type}`)
    },
    async getActivityLog(working_establishment_id: number | null) {
        const session_id = store.getters['auth/user_session_id']
        if (working_establishment_id) {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/workingestablishment/${working_establishment_id}`)
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/workingestablishment`)
        }
    }
}
