import { getError } from '@/utils/helpers'
import JobDescriptionService from '@/services/JobDescriptionService'
import { JobDescriptionInterface, StateJobDescriptions } from '@/types/JobDescription'

export const namespaced = true

function setJobDescriptions(commit: Function, response: any) {
    commit('SET_JOB_DESCRIPTIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateJobDescriptions = {
    jobDescriptions: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    jobDescriptionSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateJobDescriptions) {
        Object.assign(state, {
            jobDescriptions: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            jobDescriptionSelect: {}
        })
    },
    SET_JOB_DESCRIPTIONS(state: any, jobDescriptions: Array<JobDescriptionInterface>) {
        state.jobDescriptions = jobDescriptions
    },
    SET_MORE_JOB_DESCRIPTIONS(state: any, jobDescriptions: Array<JobDescriptionInterface>) {
        state.jobDescriptions = state.jobDescriptions.concat(jobDescriptions)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_JOB_DESCRIPTION_SELECT(state: any, jobDescription: JobDescriptionInterface) {
        state.jobDescriptionSelect = jobDescription
    }
}

export const actions = {
    getJobDescriptions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.getJobDescriptions(params)
                .then((response) => {
                    setJobDescriptions(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getJobDescription({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.getJobDescription(params.jobDescription_id)
                .then((response) => {
                    commit('SET_JOB_DESCRIPTION_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreJobDescriptions({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.getJobDescription(params.jobDescription_id)
                .then((response) => {
                    commit('SET_JOB_DESCRIPTION_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateJobDescription({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.updateJobDescription(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addJobDescription({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.addJobDescription(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteJobDescription({ commit }: { commit: any }, jobDescription_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.deleteJobDescription(jobDescription_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    export({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.export()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    import({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.import(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addJobDocument({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.addJobDocument(payload.job_id, payload.document)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getJobDocument({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.getJobDocument(params.job_id, params.document_uuid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateStatus({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.updateStatus(payload.job_id, payload.status)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })

    },
    getActivityLog({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionService.getActivityLog(params.jobDescription_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    jobDescriptions: (state: any) => {
        return state.jobDescriptions
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.jobDescriptions.length
    },
    lastPage: (state: any) => {
        return state.meta?.last_page || 1
    },
    totalPage: (state: any) => {
        return state.meta?.total || 1
    },
    jobDescriptionSelect: (state: any) => {
        return state.jobDescriptionSelect
    },
    getJobDescriptionById: (state: any) => (id: number) => {
        return state.jobDescriptions.filter((mat: any) => mat.id === id)[0]
    }
}
