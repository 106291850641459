import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'
import auth from '@/middleware/auth'
import middlewarePipeline from '@/router/middlewarePipeline'
import { Ability } from '@/types/Ability'
import { etat_structure } from '@/types/Session'

Vue.use(VueRouter)

/* Alimente le fil d'ariane */
const refererComponent = {
    oraux: 'Oraux',
    rh: 'Rh - Centres',
    configurations: 'Configurations',
    concours: 'concours',
    ecrits: 'Ecrits'
}

const routes = [
    {
        path: '/gestion_examinateurs',
        name: 'Disponibilités et Incompatibilités',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PREPA_VIEW,
            title: 'Gestion examinateurs',
            refererComponent: refererComponent.oraux
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion examinateurs' */ '../views/GestionExaminateurs/GestionExaminateurs.vue'
            )
    },
    {
        path: '/affectation_examinateurs',
        name: 'Affectation des examinateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PREPA_VIEW,
            title: 'Affectation des examinateurs',
            refererComponent: refererComponent.oraux
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion incompatibles' */ '../views/AffectationExaminateurs/AffectationExaminateurs.vue'
            )
    },
    {
        path: '/affectation_examinateurs_rh',
        name: 'Affectation académique',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PREPA_VIEW,
            title: 'Affectation des examinateurs ',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion incompatibles' */ '../views/AffectationExaminateurs/AffectationExaminateurs.vue'
            )
    },
    {
        path: '/gestion_incompatibles_examinateur',
        name: 'Gérer mes incompatibilités',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_PLANIF_OWN,
            title: "Gestion incompatibles par l'examinateur"
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion incompatibles' */ '../views/GestionExaminateurs/Examinateur/gestion_incompatibles_examinateur.vue'
            )
    },
    {
        path: '/groupes_intervenants',
        name: "Groupes d'intervenants",
        meta: {
            middleware: [auth],
            permissions: [Ability.RH_SPS_VIEW, Ability.RH_SPS_DOS_OWN_MANAGE, Ability.RH_ADM_DOS_OWN_MANAGE],
            title: 'groupes_intervenants'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/AffectationCorrecteurs/GroupesEcrit.vue')
    },
    {
        path: '/',
        redirect: 'dashboard',
        name: 'home',
        meta: {
            middleware: [auth],
            title: 'Home'
        }
    },
    {
        //chemin appel externe notification
        path: '/aide/:video_name',
        name: 'video',
        meta: {
            title: 'Video Tuto',
            allowAnonymous: true
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Aide/VideoViewer.vue')
    },
    {
        path: '/aide',
        name: 'aide',
        meta: {
            middleware: [auth],
            title: 'aide'
        },
        component: () => import(/* webpackChunkName: 'accept-rgpd' */ '../views/Aide/Aide.vue')
    },
    {
        path: '/dashboard',
        name: 'Tableau de bord ',
        meta: {
            middleware: [auth],
            title: 'Dashboard'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Dashboard.vue') // ,
    },
    {
        path: '/dashboard_i',
        name: 'Tableau de bord',
        meta: {
            middleware: [auth],
            title: 'Dashboard'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Dashboard.vue') // ,
    },
    {
        path: '/user',
        name: 'user',
        meta: {
            middleware: [auth],
            title: 'User'
        },
        component: () => import(/* webpackChunkName: 'user' */ '../views/User.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Login'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Auth/Login.vue')
    },
    {
        path: '/reset-password',
        name: 'Réinitialisation du mot de passe',
        meta: {
            title: 'Reset'
        },
        component: () => import(/* webpackChunkName: 'reset-password' */ '../views/Auth/ResetPassword.vue')
    },
    {
        path: '/forgot-password',
        name: 'Mot de passe oublié',
        meta: {
            title: 'Forgot'
        },
        component: () => import(/* webpackChunkName: 'forgot-password' */ '../views/Auth/ForgotPassword.vue')
    },
    {
        path: '/verify-email',
        name: "Vérification de l'adresse email",
        meta: {
            middleware: [auth],
            title: 'Verify'
        },
        component: () => import(/* webpackChunkName: 'verify-email' */ '../views/Auth/VerifyEmail.vue') // ,
    },
    {
        path: '/accept-rgpd',
        name: 'RGPD',
        meta: {
            middleware: [auth],
            title: 'RGPD'
        },
        component: () => import(/* webpackChunkName: 'accept-rgpd' */ '../views/Auth/AcceptRGPD.vue') // ,
    },
    {
        path: '/change-password',
        name: 'Changement du mot de passe',
        meta: {
            middleware: [auth],
            title: 'changePassword'
        },
        component: () => import(/* webpackChunkName: 'accept-rgpd' */ '../views/Auth/ChangePassword.vue')
    },
    {
        path: '/otpparam',
        name: 'Autentification à deux facteurs',
        meta: {
            middleware: [auth],
            title: 'otpparam'
        },
        component: () => import(/* webpackChunkName: 'accept-rgpd' */ '../views/Auth/OtpParam.vue')
    },
    {
        path: '/infouser',
        name: 'Information du compte',
        meta: {
            middleware: [auth],
            title: 'infouser'
        },
        component: () => import(/* webpackChunkName: 'accept-rgpd' */ '../views/Auth/InfoUser.vue')
    },
    {
        path: '/profil',
        name: 'profil',
        meta: {
            middleware: [auth],
            title: 'profil'
        },
        component: () => import(/* webpackChunkName: 'accept-rgpd' */ '../views/Auth/ProfilUser.vue')
    },
    {
        path: '/users',
        name: 'utilisateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_UTIL_VIEW,
            title: 'Users',
            refererComponent: refererComponent.configurations
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Users/Users.vue')
    },
    {
        path: '/roles',
        name: 'Rôles',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_UTIL_VIEW,
            title: 'Roles',
            refererComponent: refererComponent.configurations
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Users/Roles.vue')
    },
    {
        path: '/matieres',
        name: 'Matières',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'Matieres'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Matieres/Matieres.vue')
    },
    {
        path: '/sessions',
        name: 'Sessions',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_INI_VIEW,
            title: 'Sessions',
            refererComponent: refererComponent.configurations
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Initialisation.vue')
    },
    {
        path: '/session/:session_id',
        name: 'Session',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_INI_VIEW,
            title: 'Sessions',
            refererComponent: refererComponent.configurations
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Sessions/DetailsSession.vue'),
        props: true
    },
    {
        path: '/editconcour/:session_id/:concour_id',
        name: 'Édition concours',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_INI_VIEW,
            title: 'Concours',
            refererComponent: refererComponent.configurations
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Sessions/EditionConcour.vue'),
        props: true
    },
    {
        path: '/postes',
        name: 'Postes',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'Postes',
            refererComponent: refererComponent.configurations
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Postes/Postes.vue')
    },
    {
        path: '/etablissements',
        name: 'Établissements',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'Établissements',
            refererComponent: refererComponent.configurations
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Etablissements/Etablissements.vue')
    },
    {
        path: '/bourse_emploi/etablissements',
        name: 'Bourse à l\'emploi - Établissements',
        meta: {
            middleware: [auth],
            permissions: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.INTERV_OWN_ESTABLISHMENT],
            title: 'Etablissements'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/ListeEtablissements.vue')
    },
    {
        path: '/bourse_emploi/etablissements/:working_establishment_id',
        name: 'Bourse à l\'emploi - Établissement',
        meta: {
            middleware: [auth],
            permissions: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.INTERV_OWN_ESTABLISHMENT],
            title: 'Etablissement'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/GestionEtablissement.vue')
    },
    {
        path: '/bourse_emploi/utilisateurs',
        name: 'Bourse à l\'emploi - Utilisateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_ESTABLISHMENT_VIEW,
            title: 'Utilisateurs'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/ListeUtilisateurs.vue')
    },
    {
        path: '/bourse_emploi/postes',
        name: 'Bourse à l\'emploi - Postes',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_ESTABLISHMENT_VIEW,
            title: 'Postes'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/ListePostes.vue')
    },
    {
        path: '/bourse_emploi/candidats',
        name: 'Bourse à l\'emploi - Liste des candidats',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_ESTABLISHMENT_VIEW,
            title: 'Liste des candidats'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/ListeCandidats.vue')
    },
    {
        path: '/bourse_emploi/candidatures',
        name: 'Bourse à l\'emploi - Candidatures',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_ESTABLISHMENT_VIEW,
            title: 'Candidatures'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/ListeCandidatures.vue')
    },
    {
        path: '/bourse_emploi/candidatures/:candidat_job_description_id',
        name: 'Bourse à l\'emploi - Candidature',
        meta: {
            middleware: [auth],
            permissions: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.INTERV_OWN_ESTABLISHMENT],
            title: 'Candidatures'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/Candidature.vue')
    },
    {
        path: '/bourse_emploi/formulaire_profil',
        name: 'Bourse à l\'emploi - Formulaire profil',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_ESTABLISHMENT_VIEW,
            title: 'Formulaire profil'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BourseEmploi/JobProfileForm.vue')
    },
    {
        path: '/notificationTemplates',
        name: 'modèles de notification',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'Models de notification',
            refererComponent: refererComponent.configurations
        },
        component: () =>
            import(
                /* webpackChunkName: 'dashboard' */ '../views/Administration/NotificationTemplates/NotificationTemplates.vue'
            )
    },
    {
        path: '/notificationTemplates/:notification_id',
        name: 'edition modèles de notification',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'Models de notification ',
            refererComponent: refererComponent.configurations
        },
        component: () =>
            import(
                /* webpackChunkName: 'dashboard' */ '../views/Administration/NotificationTemplates/EditNotificationTemplates.vue'
            )
    },
    {
        path: '/reclamationTemplates',
        name: 'Modèles de réponses aux réclamations',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'Modèles de réponses aux réclamations',
            refererComponent: refererComponent.configurations
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Administration/ReclamationTemplates.vue')
    },
    {
        path: '/rgpdEntities',
        name: 'Liste des modèles rgpd',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'rgpdEntities'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Administration/RgpdEntities/RgpdEntities.vue')
    },
    {
        path: '/etatDataRgpd',
        name: 'rgpd',
        meta: {
            middleware: [auth],
            permissions: Ability.ADM_PAR_VIEW,
            title: 'etatDataRgpd',
            refererComponent: refererComponent.configurations
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Administration/RgpdEntities/EtatDataRgpd.vue')
    },
    {
        path: '/dossier_academique/:dossier_id',
        name: 'dossier académique',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_SPS_DOS_OWN_MANAGE,
            title: 'Dossier Académique'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Dossiers/DossierAcademique.vue'),
        props: true
    },
    {
        path: '/dossiers_academiques',
        name: 'dossiers académiques',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_SPS_VIEW,
            title: 'Dossiers Académiques',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Dossiers/DossiersAcademiquesRespSps.vue')
    },
    {
        path: '/selections',
        name: 'Sélections des intervenants',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_SPS_VIEW,
            title: 'selections',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(/* webpackChunkName: 'login' */ '../views/SelectionIntervenants/SelectionIntervenants.vue')
    },
    {
        path: '/selections/:pv_id/sessions/:session_id/matiere/:matiere_id',
        name: 'Sélection des intervenants',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_SPS_VIEW,
            title: 'Sélection dossier académique',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Dossiers/SelectionDossiersAcademiques.vue'),
        props: true
    },
    {
        path: '/dossier_administratif/:dossier_id',
        name: 'Dossier administratif',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_ADM_DOS_OWN_MANAGE,
            title: 'Dossier Administratif'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Dossiers/DossierAdministratif.vue'),
        props: true
    },
    {
        path: '/affectations',
        name: 'Affectations des intervenants',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_SPS_VIEW,
            title: 'affectations',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(/* webpackChunkName: 'login' */ '../views/AffectationIntervenants/AffectationIntervenants.vue')
    },
    {
        path: '/affectations/:pv_id/sessions/:session_id/matiere/:matiere_id',
        name: 'Affectation des intervenants',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_SPS_VIEW,
            title: 'Affectation dossier académique',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Dossiers/AffectationDossiersAcademiques.vue'),
        props: true
    },
    {
        path: '/contrats_types',
        name: 'Types de contrats',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_CONTRAT_VIEW,
            title: 'Contrats types',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/ContratsTypes/ContratsTypes.vue')
    },
    {
        path: '/contrats_types/:contrat_type_id',
        name: 'Gestion des types de contrats',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_CONTRAT_VIEW,
            title: 'Edition contrats types',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/ContratsTypes/EditionContratType.vue')
    },
    {
        path: '/baremes_remunerations',
        name: 'Barèmes de rémunération',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_CONTRAT_VIEW,
            title: 'Barèmes de rémunération',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BaremesRemunerations/BaremesRemunerations.vue')
    },
    {
        path: '/baremes_remunerations/:bareme_id',
        name: 'Gestion des barèmes de rémunération',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_CONTRAT_VIEW,
            title: 'Edition barème de rémunération',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/BaremesRemunerations/EditionBaremeRemuneration.vue')
    },
    {
        path: '/dossiers_administratifs',
        name: 'Dossiers administratifs',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_DOSADM_VIEW,
            title: 'Dossiers Administratifs',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Dossiers/DossiersAdministratifsGlobal.vue')
    },
    {
        path: '/contrats',
        name: 'Contrats',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_CONTRAT_VIEW,
            title: 'Contrats',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Contrats/ContratsGlobal.vue')
    },
    {
        path: '/contrats_intervenant',
        name: 'Gérer mes contrats',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_ADM_DOS_OWN_MANAGE,
            title: 'Contrats intervenant'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Contrats/ContratsIntervenant.vue')
    },
    {
        path: '/signature_fiche_horaire',
        name: 'Fiches horaires',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_ADM_DOS_OWN_MANAGE,
            title: 'Signature fiche horaire'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/SignatureFicheHoraire.vue')
    },
    {
        path: '/ordres_mission',
        name: 'Ordres de mission',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_FRAIS_VIEW,
            title: 'Ordres de mission',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(/* webpackChunkName: 'login' */ '../views/OrdresMission/GestionOrdresMissionRespAdm.vue')
    },
    {
        path: '/demandes_remboursement',
        name: 'Demande de remboursement',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_FRAIS_VIEW,
            title: 'Demandes de remboursement',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(
                /* webpackChunkName: 'login' */ '../views/DemandesRemboursement/GestionDemandesRemboursementRespAdm.vue'
            )
    },
    {
        path: '/signature_documents',
        name: 'Signature des documents',
        meta: {
            middleware: [auth],
            permissions: [Ability.SIGN_CONTRAT_VIEW, Ability.SIGN_PVA_VIEW, Ability.SIGN_LETTRE_VIEW, Ability.SIGN_CONVENTION_VIEW, Ability.SIGN_CONVENTIONANNEXE_VIEW, Ability.SIGN_FHRECAP_VIEW, Ability.SIGN_RECLAMATION_VIEW, Ability.SIGN_AVENANT_VIEW, Ability.SIGN_AMENAGEMENT_VIEW],
            title: 'Signature Documents'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Direction/SignatureDocuments.vue')
    },
    {
        path: '/candidats_oraux',
        name: 'Candidats oraux',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_VIEW,
            title: 'Candidats',
            refererComponent: refererComponent.oraux
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Candidats/Candidats.vue')
    },
    {
        path: '/candidats_ecrits',
        name: 'Candidats ecrits',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_VIEW,
            title: 'Candidats',
            refererComponent: refererComponent.ecrits
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Candidats/CandidatsEcrits.vue')
    },
    {
        path: '/candidats_concours',
        name: 'Gestion des candidats',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_VIEW,
            title: 'Candidats',
            refererComponent: refererComponent.concours
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Candidats/Candidats.vue')
    },
    {
        path: '/liste_candidats',
        name: 'Liste des candidats',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_CHOIX_POSTES_VIEW,
            title: 'Candidats'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Candidats/ListeCandidats.vue')
    },
    {
        path: '/liste',
        name: 'Listes',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_CHOIX_POSTES_VIEW,
            title: 'Listes'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Candidats/Listes.vue')
    },
    {
        path: '/formulaires_inscriptions',
        name: 'Formulaires inscriptions',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_FORMINSC_VIEW,
            title: 'Formulaires'
        },
        component: () => import(/* webpackChunkName: 'inscriptions' */ '../views/Inscriptions/ListeFormulaires.vue')
    },
    {
        path: '/formulaires_inscriptions/:publication_id',
        name: 'Edition formulaire inscription',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_FORMINSC_VIEW,
            title: 'Edition formulaire'
        },
        component: () => import(/* webpackChunkName: 'inscriptions' */ '../views/Inscriptions/EditFormulaire.vue')
    },
    {
        path: '/dossiers_inscriptions',
        name: 'Liste formulaires inscriptions',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_DOSSIER_VIEW,
            title: 'Dossiers'
        },
        component: () =>
            import(/* webpackChunkName: 'inscriptions' */ '../views/Inscriptions/ListeFormulairesDossiers.vue')
    },
    {
        path: '/dossiers_inscriptions/:publication_id',
        name: 'Liste dossiers inscriptions',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_DOSSIER_VIEW,
            title: 'Liste dossiers'
        },
        component: () => import(/* webpackChunkName: 'inscriptions' */ '../views/Inscriptions/ListeDossiers.vue')
    },
    {
        path: '/dossiers_inscriptions/:publication_id/:dossier_id',
        name: 'Edition dossier inscription',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_DOSSIER_VIEW,
            title: 'Edition dossier'
        },
        component: () => import(/* webpackChunkName: 'inscriptions' */ '../views/Inscriptions/EditDossier.vue')
    },
    {
        path: '/liste_postes',
        name: 'Liste des postes',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_CHOIX_POSTES_VIEW,
            title: 'Postes'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Candidats/ListePostes.vue')
    },
    {
        path: '/historique_notifications',
        name: 'Historique des notifications',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_CHOIX_POSTES_VIEW,
            title: 'Historique des notifications'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Candidats/HistoriqueNotifications.vue')
    },
    {
        path: '/exemple',
        name: 'Exemple',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_VIEW,
            title: 'Exemple'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Exemples/Exemples.vue')
    },
    {
        path: '/exemple_intervenant',
        name: 'exemple_intervenant',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_VIEW,
            title: 'Exemple INTERVENANT'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Exemples/ExempleIntervenant.vue')
    },
    {
        path: '/ordres_mission_intervenant',
        name: 'Gérer mes ordres de mission',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_ADM_DOS_OWN_MANAGE,
            title: 'Ordres de mission intervenant'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/OrdresMission/OrdresMissionIntervenant.vue')
    },
    {
        path: '/demandes_remboursement_intervenant',
        name: 'Gérer mes demandes de remboursement',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_ADM_DOS_OWN_MANAGE,
            title: 'Demande de remboursement intervenant'
        },
        component: () =>
            import(
                /* webpackChunkName: 'dashboard' */ '../views/DemandesRemboursement/DemandesRemboursementIntervenant.vue'
            )
    },
    {
        path: '/villes',
        name: 'Villes',
        meta: {
            middleware: [auth],
            permissions: Ability.GC_CENTER_VIEW,
            title: 'villes',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Sol/Villes.vue')
    },
    {
        path: '/centres_ecrits',
        name: "Centres d'écrit",
        meta: {
            middleware: [auth],
            permissions: Ability.GC_CENTER_VIEW,
            title: 'centres',
            refererComponent: refererComponent.ecrits
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Sol/GestionCentres.vue')
    },
    {
        path: '/centres_oraux',
        name: "Centres d'oral",
        meta: {
            middleware: [auth],
            permissions: Ability.GC_CENTER_VIEW,
            title: 'centres',
            refererComponent: refererComponent.oraux
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Sol/GestionCentres.vue')
    },
    {
        path: '/centres/:centre_id',
        name: 'Centres ',
        meta: {
            middleware: [auth],
            permissions: Ability.GC_CENTER_VIEW,
            title: 'Édition centre',
            refererComponent: refererComponent.configurations
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Sol/EditionCentre.vue')
    },
    {
        path: '/centres/:centre_id/adresses',
        name: 'Salles du centre',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_INFOS_OWN_VIEW,
            title: 'Salles du centre'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Adresses.vue')
    },
    {
        path: '/centres/:centre_id/intervenants',
        name: 'GESTION DES INTERVENANTS',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_SAISIE_INTERV_OWN_VIEW,
            title: 'cdc_intervenants'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Intervenants.vue')
    },
    /* {
        path: '/centres/:centre_id/informations_r',
        name: 'Gestion des informations ',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_INFOS_OWN_VIEW,
            title: 'cdc_informations'
        },
        component: () => import('../views/Sol/CDC/Informations.vue')
    },
    {
        path: '/centres/:centre_id/description_r',
        name: 'Description et Contacts ',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_DESCR_OWN_VIEW,
            title: 'cdc_description'
        },
        component: () => import('../views/Sol/CDC/DescriptionCentre.vue')
    }, */
    {
        path: '/centres/:centre_id/signature_intervenant',
        name: 'FICHES HORAIRES DES INTERVENANTS',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_FICHES_HORAIRES_OWN_VIEW,
            title: 'resp_intervenants'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Intervenants.vue')
    },
    {
        path: '/centres/:centre_id/messagecandidats',
        name: "Message à l'attention des candidats",
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_INFOS_OWN_VIEW,
            title: 'cdc_messagecandidats'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/MessageCandidats.vue')
    },
    {
        path: '/centres/:centre_id/conventions',
        name: 'Consultation de la convention',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CONVENTION_OWN_VIEW,
            title: 'cdc_convention'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Conventions/ConventionsIntervenants.vue')
    },
    {
        path: '/centres/:centre_id/annexeconvention',
        name: "Gestion de l'annexe de convention",
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_ANNEXE_CONVENTION_OWN_VIEW,
            title: 'cdc_annexeconvention'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/AnnexeConvention.vue')
    },
    {
        path: '/centres/:centre_id/salles',
        name: 'cdc_salles',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_ORGA_OWN_VIEW,
            title: 'cdc_salles'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Salles.vue')
    },
    {
        path: '/centres/:centre_id/informations',
        name: 'Gestion des informations',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_INFOS_OWN_VIEW,
            title: 'cdc_informations'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Informations.vue')
    },
    {
        path: '/centres/:centre_id/description',
        name: 'Description et Contacts',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_DESCR_OWN_VIEW,
            title: 'cdc_description'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/DescriptionCentre.vue')
    },
    {
        path: '/centres/:centre_id/organisationecrit/salles',
        name: 'Gestion des salles',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_ORGA_OWN_VIEW,
            title: 'cdc_salles_ecrit'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/SallesEcrit.vue')
    },
    {
        path: '/centres/:centre_id/organisationecrit/nb_intervenants',
        name: "Gestion du nombre d'intervenants",
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_ORGA_OWN_VIEW,
            title: 'cdc_intervenants_ecrit'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/IntervenantsEcrit.vue')
    },
    {
        path: '/centres/:centre_id/organisationoral',
        name: 'Affectation des salles aux examinateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_ORGA_OWN_VIEW,
            title: 'cdc_organisation_oral'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/OrganisationOral.vue')
    },
    {
        path: '/politique-vie-privee',
        name: 'Politique de vie privée',
        meta: {
            middleware: [auth],
            title: 'Politique de vie privee'
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Auth/PolitiqueViePrivee.vue')
    },
    {
        path: '/conventions',
        name: 'Conventions',
        meta: {
            middleware: [auth],
            permissions: Ability.GC_CONVENTION_VIEW,
            title: 'Conventions',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Conventions/ConventionsRespAdm.vue')
    },
    {
        path: '/conventions_types',
        name: 'Types de conventions',
        meta: {
            middleware: [auth],
            permissions: Ability.GC_CONVENTION_VIEW,
            title: 'Conventions types',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/ConventionTypes/ConventionsTypes.vue')
    },
    {
        path: '/conventions_types/:convention_type_id',
        name: 'Gestion des types de conventions',
        meta: {
            middleware: [auth],
            permissions: Ability.GC_CONVENTION_VIEW,
            title: 'Edition types de conventions',
            refererComponent: refererComponent.rh
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/ConventionTypes/EditionConventionType.vue')
    },
    {
        path: '/conventions_own',
        name: 'Gérer mes conventions',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CONVENTION_OWN_VIEW,
            title: 'Conventions intervenant'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Conventions/ConventionsIntervenants.vue')
    },
    {
        path: '/intervenants',
        name: 'Intervenants',
        meta: {
            middleware: [auth],
            permissions: Ability.RH_SOL_VIEW,
            title: 'intervenants',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'login' */ '../views/Sol/Intervenants.vue')
    },
    {
        path: '/gestion_disponibilites_examinateur',
        name: 'Gérer mes disponibilités',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_PLANIF_OWN,
            title: "Gestion disponibilites par l'examinateur"
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion incompatibles' */ '../views/GestionExaminateurs/Examinateur/GestionDisponibilitesExaminateur.vue'
            )
    },
    {
        // ORAL_PREPA_VIEW
        path: '/dashboardOraux',
        name: 'Tableau de bord des oraux',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PREPA_VIEW,
            title: 'Tableau de bord des oraux'
        },
        component: () => import(/* webpackChunkName: 'not-found' */ '../views/DashboardOraux.vue')
    },
    {
        // DLB_PREPJURY_VIEW
        path: '/liste_epreuve_correction_resultats',
        name: 'Déliberation - Ajustement des notes',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PREPJURY_VIEW,
            title: 'Déliberation-Ajustement des notes',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/Deliberation/ListeEpreuveCorrectionResultats.vue')
    },
    {
        // DLB_PREPJURY_VIEW
        path: '/liste_epreuve_correction_resultats/:epreuve_correction_id',
        name: 'Déliberation - Ajustement des notes ',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PREPJURY_VIEW,
            title: 'Déliberation - Ajustement des notes',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/Deliberation/EpreuveCorrectionCandidats.vue')
    },
    {
        // DLB PUBNOT VIEW
        path: '/resultats',
        name: 'Résultats - Liste des concours',
        meta: {
            middleware: [auth],
            permissions: Ability.EXT_RESULTS_VIEW,
            title: 'Résultats - Liste des concours',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/DefinitionDesBarres/DefinitionDesBarres.vue')
    },
    {
        // DLB PUBNOT VIEW
        path: '/resultats/:id',
        name: 'Résultats - Notes',
        meta: {
            middleware: [auth],
            permissions: Ability.EXT_RESULTS_VIEW,
            title: 'Résultats - Notes',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/DefinitionDesBarres/DefinitionDesBarresOnglets.vue')
    },
    {
        // DLB PUBNOT VIEW
        path: '/resultats_admission/:id',
        name: 'Résultats - Notes admission',
        meta: {
            middleware: [auth],
            permissions: Ability.EXT_RESULTS_VIEW,
            title: 'Résultats - Notes admission',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/DefinitionDesBarres/DefinitionDesBarresAdmission.vue')
    },
    {
        // DLB PUBNOT VIEW
        path: '/definition_des_barres',
        name: 'Déliberation - Définition des barres',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PUBNOT_VIEW,
            title: 'Déliberation - Définition des barres',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/DefinitionDesBarres/DefinitionDesBarres.vue')
    },
    {
        // DLB PUBNOT VIEW
        path: '/definition_des_barres/:id',
        name: 'Déliberation - Définition des barres ',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PUBNOT_VIEW,
            title: 'Déliberation - Définition des barres',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/DefinitionDesBarres/DefinitionDesBarresOnglets.vue')
    },
    {
        // DLB PUBNOT VIEW
        path: '/definition_des_barres_admission/:id',
        name: 'Déliberation - Admission',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PUBNOT_VIEW,
            title: 'Déliberation - Admission',
            refererComponent: refererComponent.concours
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/DefinitionDesBarres/DefinitionDesBarresAdmission.vue')
    },
    {
        path: '/planification',
        name: 'Planification',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PLAN_VIEW,
            title: 'Gestion de la planification',
            refererComponent: refererComponent.oraux
        },
        component: () => import(/* webpackChunkName: 'not-found' */ '../views/Planification/Planification.vue')
    },
    {
        path: '/gestion_portail_candidats',
        name: 'Portail candidats',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PUBNOT_VIEW,
            title: 'Gestion du portail candidat'
        },
        component: () =>
            import(/* webpackChunkName: 'not-found' */ '../views/GestionPortailCandidat/GestionPortailCandidat.vue')
    },
    {
        path: '/exemple_no_tabs',
        name: 'EXEMPLE SANS NAVS',
        component: () => import(/* webpackChunkName: 'not-found' */ '../views/Exemples/ExempleComposantWithoutNav.vue')
    },
    {
        path: '/dashboardConcours',
        name: 'Tableau de bord concours',
        meta: {
            middleware: [auth],
            permissions: Ability.CAND_VIEW,
            title: 'Tableau de bord concours'
        },
        component: () => import(/* webpackChunkName: 'not-found' */ '../views/DashboardConcours.vue')
    },
    {
        path: '/gestion_reclamations/:phase',
        name: 'Réclamations ',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_RECLAM_VIEW,
            title: 'Gestion des reclamations'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/Reclamations.vue')
    },
    {
        path: '/reclamations_ecrit',
        name: "Réclamations sur les notes d'écrit",
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_RECLAM_VIEW,
            title: "Réclamations sur les notes d'écrit"
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsEcrit.vue')
    },
    {
        path: '/reclamations_ecrit/:reclamation_id',
        name: "Gestion d'une réclamation sur une note d'écrit",
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_RECLAM_VIEW,
            title: "Gestion d'une réclamation sur une note d'écrit"
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsEcritGestion.vue')
    },
    {
        path: '/reclamations_oral',
        name: "Réclamations des séances d'oral ",
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_RECLAM_VIEW,
            title: "Gestion des réclamations des séances d'oral"
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsOral.vue')
    },
    {
        path: '/reclamations_oral/:reclamation_id',
        name: "Gestion d'une réclamation sur une séance d'oral",
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_RECLAM_VIEW,
            title: "Gestion des réclamations  sur une séance d'oral"
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsOralGestion.vue')
    },
    {
        path: '/reclamations_post_concours/',
        name: "Gestion des réclamations des notes d'oral",
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_RECLAM_VIEW,
            title: "Gestion des réclamations des notes d'oral"
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsPostConcours.vue')
    },
    {
        path: '/reclamations_post_concours/:reclamation_id',
        name: "Gestion d'une réclamation sur une note d'oral",
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_RECLAM_VIEW,
            title: "Gestion d'une réclamation sur une note d'oral"
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsPostGestion.vue')
    },
    {
        path: '/reclamations_post_concours/:reclamation_id/reclassement/:candidat_id/concours/:concour_id',
        name: 'Reclassement',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_RECLAM_VIEW,
            title: 'Reclassement'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/Reclassement.vue')
    },
    {
        path: '/gestion_demissions',
        name: 'démissions',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_RECLAM_VIEW,
            title: 'Gestion des demissions'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Demissions/gestionDemissions.vue')
    },
    {
        path: '/impressions',
        name: 'impressions',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_GFOU_VIEW,
            title: 'Gestion des impressions',
            refererComponent: refererComponent.oraux
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Fournitures/Fournitures.vue')
    },
    {
        path: '/plannings',
        name: 'plannings',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_SUIVI_VIEW,
            title: 'Gestion des plannings',
            refererComponent: refererComponent.oraux
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Plannings/Plannings.vue')
    },
    {
        path: '/suppleant',
        name: 'Suppleants',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_SUIVI_VIEW,
            title: 'Suppleants',
            refererComponent: refererComponent.oraux
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Suppleant/Suppleant.vue')
    },
    {
        path: '/planning_examinateur',
        name: 'Consulter mon planning',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_PLANIF_OWN,
            title: "Consultation du planning par l'examinateur"
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion incompatibles' */ '../views/GestionExaminateurs/Examinateur/ConsultationPlanning.vue'
            )
    },
    {
        path: '/liste_candidats_mesures',
        name: 'Candidats avec aménagements',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_PLANIF_OWN,
            title: 'Candidats avec aménagements'
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion incompatibles' */ '../views/GestionExaminateurs/Examinateur/ListeCandidatsAmenagements.vue'
            )
    },
    {
        path: '/liste_candidats_mesures_supp',
        name: 'Candidats avec aménagements ',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_SUPPLEANT_OWN,
            title: 'Candidats avec aménagements'
        },
        component: () =>
            import(
                /* webpackChunkName: 'Gestion incompatibles' */ '../views/GestionExaminateurs/Examinateur/ListeCandidatsAmenagements.vue'
            )
    },
    {
        path: '/centres/:centre_id/impressions',
        name: 'Impressions',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_IMPRESSIONS_OWN_VIEW,
            title: 'cdc_impressions_oral'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Fournitures/Fournitures.vue')
    },
    {
        path: '/centres/:centre_id/plannings',
        name: 'Plannings',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_PLANNINGS_OWN_VIEW,
            title: 'cdc_plannings_oral'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Plannings.vue')
    },
    {
        path: '/centres/:centre_id/examinateurs',
        name: 'Liste des examinateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_EXAMINATEURS_OWN_VIEW,
            title: 'cdc_examinateurs'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/ListeExaminateurs.vue')
    },
    {
        path: '/centres/:centre_id/candidats',
        name: 'Liste des candidats ',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CANDIDATS_OWN_VIEW,
            title: 'cdc_candidats'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/ListeCandidats.vue')
    },
    {
        path: '/centres/:centre_id/demissionnaires',
        name: 'Liste des candidats démissionnaires',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_DEMISSIONNAIRES_OWN_VIEW,
            title: 'démissionnaires'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Demissionnaires.vue')
    },
    {
        path: '/liste_demissionnaires',
        name: 'Liste des candidats démissionnaires ',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_PLANIF_OWN,
            title: 'démissionnaires'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Demissionnaires.vue')
    },
    {
        path: '/liste_demissionnaires_supp',
        name: 'Liste des candidats démissionnaires  ',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_SUPPLEANT_OWN,
            title: 'démissionnaires'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Demissionnaires.vue')
    },
    {
        path: '/reclamations_observateur',
        name: 'Suivi des réclamations',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_OBSERV_MATIERE_OWN,
            title: 'Suivi des réclamations'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsOral.vue')
    },
    {
        path: '/reclamations_observateur/:reclamation_id',
        name: 'Consulter une réclamation',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_OBSERV_MATIERE_OWN,
            title: 'Consulter une réclamation'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Reclamations/ReclamationsOralGestion.vue')
    },
    {
        path: '/plannings_observateur',
        name: 'Consultation des plannings',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_OBSERV_MATIERE_OWN,
            title: 'Consultation des plannings'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Plannings.vue')
    },
    {
        path: '/presence_examinateurs',
        name: 'Présence des examinateurs par centre',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_OBSERV_MATIERE_OWN,
            title: 'Présence des examinateurs par centre'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Observateur/PresenceExaminateurs.vue')
    },
    {
        path: '/trombinoscope_examinateurs',
        name: 'Trombinoscope des examinateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_OBSERV_MATIERE_OWN,
            title: 'Trombinoscope des examinateurs'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Observateur/TrombinoscopeExaminateurs.vue')
    },
    {
        path: '/trombinoscope_examinateurs_co',
        name: 'Trombinoscope des examinateurs ',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_EXAMINATEURS_OWN_VIEW,
            title: 'Trombinoscope des examinateurs '
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Observateur/TrombinoscopeExaminateurs.vue')
    },
    {
        path: '/trombinoscope_examinateurs_g',
        name: 'Trombinoscope des examinateurs  ',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_AFEX_VIEW,
            title: 'Trombinoscope des examinateurs  '
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Observateur/TrombinoscopeExaminateurs.vue')
    },
    {
        path: '/trombinoscope_examinateurs_ex',
        name: 'Trombinoscope des examinateurs     ',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_PLANIF_OWN,
            title: 'Trombinoscope des examinateurs'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Observateur/TrombinoscopeExaminateurs.vue')
    },
    {
        path: '/planning_suppleant',
        name: 'Consulter mon planning ',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_SUPPLEANT_OWN,
            title: 'Consultation du planning par le suppléant'
        },
        component: () =>
            import(
                /* webpackChunkName: 'dashboard' */ '../views/GestionExaminateurs/Examinateur/ConsultationPlanning.vue'
            )
    },
    {
        path: '/documents_examinateur',
        name: 'Dépôt de fichier',
        meta: {
            middleware: [auth],
            permissions: Ability.INTERV_PLANIF_OWN,
            title: 'Dépôt des fichiers'
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Modules/ModuleGestionDocumentsExaminateur.vue')
    },
    {
        path: '/liste_documents_examinateur',
        name: 'Dépôt des fichiers des examinateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_SEANCE_RECLAM_VIEW,
            title: 'Dépôt des fichiers des examinateurs'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Modules/ListeDocumentsExaminateur.vue')
    },
    {
        path: '/decisions_jury',
        name: 'Délibération - Décisions jury',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PREPJURY_VIEW,
            title: 'Décisions jury',
            refererComponent: refererComponent.concours
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/DecisionJury/DecisionsJury.vue')
    },
    {
        path: '/avenants_types',
        name: "Types d'avenant",
        meta: {
            middleware: [auth],
            permissions: Ability.RH_CONTRAT_VIEW,
            title: 'Avenants types',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/AvenantsTypes/AvenantsTypes.vue')
    },
    {
        path: '/avenants_types/:avenant_type_id',
        name: "Gestion des types d'avenant",
        meta: {
            middleware: [auth],
            permissions: Ability.RH_CONTRAT_VIEW,
            title: 'Edition avenants types',
            refererComponent: refererComponent.rh
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/AvenantsTypes/EditionAvenantType.vue')
    },
    {
        path: '/repartition_candidats',
        name: 'Répartition des candidats',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'Repartition des candidat',
            refererComponent: refererComponent.ecrits
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Repartition/ListeCentres.vue')
    },
    {
        path: '/placements_centre/:centre_id',
        name: 'Placements',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'Placements',
            refererComponent: refererComponent.ecrits
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Repartition/Placements.vue')
    },
    {
        // ECR_PREPA_VIEW
        path: '/dashboardEcrits',
        name: 'Tableau de bord des écrits',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'Tableau de bord des écrits'
        },
        component: () => import(/* webpackChunkName: 'not-found' */ '../views/DashboardEcrits.vue')
    },
    {
        path: '/gestion_repartitions',
        name: 'Gestion des répartitions',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'Gestion des répartitions',
            refererComponent: refererComponent.ecrits
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Repartition/GestionRepartitions.vue')
    },
    {
        path: '/session/:session_id/gestion_equipes',
        name: 'Gestion des équipes',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PREPA_VIEW,
            title: 'Gestion des équipes',
            refererComponent: refererComponent.configurations
        },
        component: () =>
            import(/* webpackChunkName: 'dashboard' */ '../views/Administration/Sessions/GestionEquipe.vue'),
        props: true
    },
    {
        path: '/centres/:centre_id/candidats_ecrits',
        name: 'Liste des candidats  ',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CANDIDATS_OWN_VIEW,
            title: 'cdc_candidats'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/ListeCandidatsEcrits.vue')
    },
    {
        path: '/centres/:centre_id/inventaire_fp',
        name: 'Inventaire des fournitures papetières',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CANDIDATS_OWN_VIEW,
            title: 'cdc_inventaire'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Ecrits/InventaireFp.vue')
    },
    {
        path: '/centres/:centre_id/reception_sujets',
        name: 'Réception des sujets',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CANDIDATS_OWN_VIEW,
            title: 'cdc_reception_sujets'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Ecrits/ReceptionSujets.vue')
    },
    {
        path: '/centres/:centre_id/reception_fp',
        name: 'Réception des fournitures papetières',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CANDIDATS_OWN_VIEW,
            title: 'cdc_reception_fp'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Ecrits/ReceptionFp.vue')
    },
    {
        path: '/centres/:centre_id/reception_fa',
        name: 'Réception des fournitures administratives',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CANDIDATS_OWN_VIEW,
            title: 'cdc_reception_fa'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Ecrits/ReceptionFa.vue')
    },
    {
        path: '/centres/:centre_id/bibliotheque_documents_administratifs',
        name: 'Bibliothèque des documents administratifs',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_CANDIDATS_OWN_VIEW,
            title: 'cdc_bibliotheque_documents_administratifs'
        },
        component: () =>
            import(
                /* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Ecrits/BibliothequeDocumentsAdministratifs.vue'
            )
    },
    {
        path: '/suivi_sujets_fournitures',
        name: 'Suivi des sujets & fournitures',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'suivi_sujets_fournitures'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Ecrits/SuiviFournitures.vue')
    },
    {
        path: '/suivi_sujets_fournitures/:centre_id',
        name: 'Suivi des sujets & fournitures ',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'suivi_sujets_fournitures_centre'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Ecrits/SuiviFournituresCentre.vue')
    },
    {
        path: '/production_fournitures',
        name: 'Production des fournitures',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'production_fournitures'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/Ecrits/ProductionFournitures.vue')
    },
    {
        path: '/affectation_correcteurs',
        name: 'Affectation des correcteurs',
        meta: {
            middleware: [auth],
            permissions: Ability.ECR_PREPA_VIEW,
            title: 'Affectation des correcteurs'
        },
        component: () => import('../views/AffectationCorrecteurs/AffectationCorrecteurs.vue')
    },
    {
        path: '/organisation_examinateurs',
        name: 'Organisation des examinateurs',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PREPA_VIEW,
            title: 'Organisation des examinateurs'
        },
        component: () => import('../views/GestionExaminateurs/OrganisationExaminateurs.vue')
    },
    {
        path: '/seances_en_anomalies',
        name: 'Séances en anomalies',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_AFEX_MANAGE,
            title: 'Séances en anomalies'
        },
        component: () => import('../views/GestionExaminateurs/SeancesEnAnomalies.vue')
    },
    {
        path: '/problemes_deontologie',
        name: 'Problèmes de déontologie',
        meta: {
            middleware: [auth],
            permissions: Ability.ORAL_PREPA_VIEW,
            title: 'Problèmes de déontologie'
        },
        component: () => import('../views/Deontologies/Deontologies.vue')
    },
    {
        path: '/centres/:centre_id/indisponibilites_reception',
        name: 'Indisponibilités de réception de livraison',
        meta: {
            middleware: [auth],
            permissions: Ability.GCI_FOURNITURES_OWN_VIEW,
            title: 'cdc_indispos_reception'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/Sol/CDC/Ecrits/IndisposReceptionLivraison.vue')
    },
    {
        path: '/definir_barres_concours',
        name: 'Définir les barres',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PUBNOT_VIEW,
            title: 'delib_definir_barres',
            refererComponent: refererComponent.concours
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/DefinitionDesBarres/DefinirBarresConcours.vue')
    },
    {
        path: '/liste_concours',
        name: 'Liste des concours',
        meta: {
            middleware: [auth],
            permissions: Ability.EXT_RESULTS_VIEW,
            title: 'list_concours'
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/DefinitionDesBarres/DefinirBarresConcours.vue')
    },
    {
        path: '/acces_resultats',
        name: 'Accès aux résultats',
        meta: {
            middleware: [auth],
            permissions: Ability.DLB_PUBNOT_VIEW,
            title: 'delib_acces_resultats',
            refererComponent: refererComponent.concours
        },
        component: () => import(/* webpackChunkName: 'dashboard' */ '../views/DefinitionDesBarres/AccesResultats.vue')
    },
    {
        path: '/version',
        name: 'Version'
    },
    {
        path: '/:catchAll(.*)',
        name: 'Page non trouvée',
        component: () => import(/* webpackChunkName: 'not-found' */ '../views/NotFound.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to: any, from: any, savedPosition: any) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

function isUserValid(to: any, from: any, next: any, user: any) {
    if (user != null) {
        if (!store.getters['auth/isEmailVerified'] && to.path !== "/verify-email") {
            return next({ path: "/verify-email", replace: true })
        } else if (
            store.getters['auth/isEmailVerified'] &&
            !store.getters['auth/isPasswordChanged'] &&
            to.path !== '/change-password'
        ) {
            return next({ path: '/change-password', replace: true })
        } else if (
            store.getters['auth/isEmailVerified'] &&
            store.getters['auth/isPasswordChanged'] &&
            !store.getters['auth/isAcceptedRGPD'] &&
            to.path !== '/accept-rgpd' &&
            to.path !== '/politique-vie-privee'
        ) {
            return next({ path: '/accept-rgpd', replace: true })
        } else {
            const bouncer = store.getters['auth/bouncer']
            if (to.meta.permissions || to.meta.roles) {
                if (typeof to.meta.permissions === 'function') {
                    if (!to.meta.permissions(bouncer, to, from)) {
                        return next({ path: '/dashboard', replace: true })
                    }
                } else if (typeof to.meta.permissions === 'object') {
                    let check = false
                    if (to.meta.permissions.length) {
                        check = to.meta.permissions.some((permission: any) => {
                            return bouncer.can(permission)
                        })
                    }

                    if (!check) {
                        return next({ path: '/dashboard', replace: true })
                    }
                } else if (
                    (to.meta.permissions && bouncer.cannot(to.meta.permissions)) ||
                    (to.meta.roles && bouncer.isNotA(to.meta.roles))
                ) {
                    return next({ path: '/dashboard', replace: true })
                }
            }
        }
    }
}

router.beforeEach((to: any, from: any, next: any) => {
    if (to.path === '/version') {
        document.documentElement.innerHTML = (document.head.querySelector('meta[name="version"]') as any).content
        return next()
    }

    if (to && to.meta && to.meta.middleware) {
        const middleware = to.meta.middleware
        const context = { to, from, next, store }

        if (!to.path.includes('/session/') && !to.path.includes('/sessions') && !to.path.includes('/editconcour/')) {
            const sessionTemp = store.getters['session/sessions'].find(
                (s: any) => s.id === store.getters['auth/user_session_id']
            )
            if (
                sessionTemp &&
                sessionTemp.structure_valide !== etat_structure.VALIDEE &&
                store.getters['session/sessions'].length > 1
            ) {
                const sessionsValides = store.getters['session/sessions'].filter(
                    (session: any) => session.structure_valide === etat_structure.VALIDEE
                )
                if (sessionsValides.length > 0) {
                    store.commit('auth/SET_USER_SESSION_ID', Number(sessionsValides[sessionsValides.length - 1].id))
                    window.sessionStorage.setItem('user_session_id', sessionsValides[sessionsValides.length - 1].id)
                } else {
                    return next({ name: 'Sessions', replace: true })
                }
            }
        }

        if (
            !['/login', '/forgot-password', '/reset-password', '/politique-vie-privee'].includes(to.path)
        ) {
            let user = store.getters['auth/authUser']
            if (to.path !== '/login') {
                if (user == null) {
                    store
                        .dispatch('auth/getAuthUser')
                        .catch(() => {
                            return next({ path: '/login', replace: true })
                        })
                        .then(() => {
                            user = store.getters['auth/authUser']
                            isUserValid(to, from, next, user)
                        })
                } else {
                    isUserValid(to, from, next, user)
                }
            }
        }
        middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1)
        })
    } else {
        if (
            ['/login', '/forgot-password', '/reset-password'].includes(to.path) &&
            window.sessionStorage.getItem('user') &&
            window.sessionStorage.getItem('user_session_id')
        ) {
            return next({ path: '/dashboard', replace: true })
        }
        return next()
    }
})

export default router
