import PvService from '@/services/PvService'
import { Decision, DossierSelectionInterface } from '@/types/DossierAcademique'
import { PvInterface, StatePvs } from '@/types/Pv'
import { getError } from '@/utils/helpers'

export const namespaced = true

function getBesoins(commit: any, pv_id: number) {
    commit('SET_LOADING', true)
    return new Promise((resolve, reject) => {
        PvService.getBesoin(pv_id)
            .then((response) => {
                let besoins = []
                const postes: {
                    [posteLibelle: string]: {
                        selection: number,
                        affectation: number,
                        besoin: number,
                        selectionManquant: number,
                        affectationManquant: number,
                        precedent: number,
                        regroupement: string,
                        name_plural: string
                    }
                } = {}
                const besoinAutre = []
                const options: { [libelle: string]: { value: string } } = {}
                for (let i = 0; i < response.data.data.length; i++) {
                    if (!postes[response.data.data[i].poste.regroupement]) {
                        postes[response.data.data[i].poste.regroupement] = {
                            selection: 0,
                            affectation: 0,
                            besoin: 0,
                            selectionManquant: 0,
                            affectationManquant: 0,
                            precedent: 0,
                            regroupement: response.data.data[i].poste.regroupement,
                            name_plural: response.data.data[i].poste.name_plural
                        }
                    }
                    postes[response.data.data[i].poste.regroupement].selection +=
                        response.data.data[i].intervenants.selection
                    postes[response.data.data[i].poste.regroupement].affectation +=
                        response.data.data[i].intervenants.affectation
                    postes[response.data.data[i].poste.regroupement].besoin += response.data.data[i].intervenants.besoin
                    postes[response.data.data[i].poste.regroupement].selectionManquant =
                        postes[response.data.data[i].poste.regroupement].besoin -
                        postes[response.data.data[i].poste.regroupement].selection
                    postes[response.data.data[i].poste.regroupement].affectationManquant =
                        postes[response.data.data[i].poste.regroupement].besoin -
                        postes[response.data.data[i].poste.regroupement].affectation
                    postes[response.data.data[i].poste.regroupement].precedent +=
                        response.data.data[i].intervenants.precedent
                    if (response.data.data[i].epreuve) {
                        if (
                            response.data.data[i].intervenants.options &&
                            response.data.data[i].intervenants.options.length !== 0
                        ) {
                            for (const option in response.data.data[i].intervenants.options) {
                                options[option] = { value: option }
                                response.data.data[i][option] = response.data.data[i].intervenants.options[option]
                            }
                        } else {
                            // for test
                            /*
                const test = 'Coordonnateur'
                options[test] = { value: test }
                response.data.data[i][test] = 1
                */
                        }
                        const stringConcour = response?.data?.data[i]?.epreuve?.concours
                            ?.map((concour: any) => concour.name)
                            ?.join(', ') || ''
                        response.data.data[i].libelle =
                            response.data.data[i].poste.name +
                            ' ' +
                            stringConcour +
                            ' ' +
                            response.data.data[i].epreuve.name

                        besoins.push(response.data.data[i])
                    } else {
                        response.data.data[i].libelle = response.data.data[i].poste.name
                        besoinAutre.push(response.data.data[i])
                    }
                }
                besoins = besoins.sort((a, b) => {
                    if (a.libelle > b.libelle) {
                        return 1
                    } else {
                        return -1
                    }
                })
                besoinAutre.sort()
                const bs = besoins.concat(besoinAutre)
                commit('SET_LOADING', false)
                commit('SET_BESOINS', bs)
                commit('SET_BESOIN_OPTIONS', options)
                commit('SET_POSTES_PV_COURANT', postes)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

function getPosteById(postes: any, posteId: number) {
    for (let i = 0; i < postes.length; i++) {
        if (postes[i].id === posteId) {
            return postes[i]
        }
    }
}

function setPvs(commit: any, response: any, params: any | null) {
    const postes = params ? params.postes : null
    let intervBesoinGlobal = 0
    let intervSelectionGlobal = 0
    let intervAffectationGlobal = 0
    let pvSigne = 0
    let epreuvesCompleteSelection = 0
    let epreuvesCompleteAffectation = 0
    const datas = response.data.data
    const datasTemp = []
    const objPostesBesoinGlobal: {
        [posteName: string]: {
            id: number,
            name: string,
            prevuPourSession: number,
            nbSession: number,
            nbSelectionne: number,
            nbAffecte: number
        }
    } = {}
    for (let i = 0; i < datas.length; i++) {
        const postesBesoin: {
            [posteName: string]: {
                id: number,
                name: string,
                besoin: number,
                precedent: number,
                selection: number,
                affectation: number
            }
        } = {}
        let intervBesoin = 0
        let intervSelection = 0
        let intervAffectation = 0
        if (datas[i].besoins) {
            for (const p in datas[i].besoins.postes) {
                const poste = getPosteById(postes, parseInt(p))
                let indiceLibelle = 'poste_' + p
                if (poste) {
                    indiceLibelle = poste.regroupement
                }

                if (objPostesBesoinGlobal[indiceLibelle] == null) {
                    objPostesBesoinGlobal[indiceLibelle] = {
                        id: parseInt(p),
                        name: indiceLibelle,
                        prevuPourSession: 0,
                        nbSession: 0,
                        nbSelectionne: 0,
                        nbAffecte: 0
                    }
                }

                for (const model in datas[i].besoins.postes[p]) {
                    for (const modelId in datas[i].besoins.postes[p][model]) {
                        if (postesBesoin[indiceLibelle] == null) {
                            postesBesoin[indiceLibelle] = {
                                id: parseInt(p),
                                name: indiceLibelle,
                                besoin: datas[i].besoins.postes[p][model][modelId].besoin
                                    ? datas[i].besoins.postes[p][model][modelId].besoin
                                    : 0,
                                precedent: datas[i].besoins.postes[p][model][modelId].precedent
                                    ? datas[i].besoins.postes[p][model][modelId].precedent
                                    : 0,
                                selection: datas[i].besoins.postes[p][model][modelId].selection
                                    ? datas[i].besoins.postes[p][model][modelId].selection
                                    : 0,
                                affectation: datas[i].besoins.postes[p][model][modelId].affectation
                                    ? datas[i].besoins.postes[p][model][modelId].affectation
                                    : 0
                            }
                        } else {
                            postesBesoin[indiceLibelle].besoin += datas[i].besoins.postes[p][model][modelId].besoin
                                ? datas[i].besoins.postes[p][model][modelId].besoin
                                : 0
                            postesBesoin[indiceLibelle].precedent += datas[i].besoins.postes[p][model][modelId]
                                .precedent
                                ? datas[i].besoins.postes[p][model][modelId].precedent
                                : 0
                            postesBesoin[indiceLibelle].selection += datas[i].besoins.postes[p][model][modelId]
                                .selection
                                ? datas[i].besoins.postes[p][model][modelId].selection
                                : 0
                            postesBesoin[indiceLibelle].affectation += datas[i].besoins.postes[p][model][modelId]
                                .affectation
                                ? datas[i].besoins.postes[p][model][modelId].affectation
                                : 0
                        }
                        intervBesoin += datas[i].besoins.postes[p][model][modelId].besoin
                            ? datas[i].besoins.postes[p][model][modelId].besoin
                            : 0
                        intervSelection += datas[i].besoins.postes[p][model][modelId].selection
                            ? datas[i].besoins.postes[p][model][modelId].selection
                            : 0
                        intervAffectation += datas[i].besoins.postes[p][model][modelId].affectation
                            ? datas[i].besoins.postes[p][model][modelId].affectation
                            : 0
                    }
                }
                objPostesBesoinGlobal[indiceLibelle].prevuPourSession += postesBesoin[indiceLibelle].besoin
                objPostesBesoinGlobal[indiceLibelle].nbSelectionne += postesBesoin[indiceLibelle].selection
                objPostesBesoinGlobal[indiceLibelle].nbAffecte += postesBesoin[indiceLibelle].affectation
                objPostesBesoinGlobal[indiceLibelle].nbSession += 0
            }
        }
        const avancemmentPctTempSelect = intervBesoin > 0 ? (intervSelection / intervBesoin) * 100 : 0
        const avancemmentPctTempAffect = intervBesoin > 0 ? (intervAffectation / intervBesoin) * 100 : 0
        datasTemp.push({
            id: datas[i].id,
            epreuvecorrections_count: datas[i].epreuvecorrections_count,
            session_id: datas[i].session_id,
            matiere_id: datas[i].matiere_id,
            matiere_name: datas[i].matiere.name,
            intervBesoin: intervBesoin,
            intervSelection: intervSelection,
            intervAffectation: intervAffectation,
            intervManquantSelect: intervBesoin - intervSelection,
            intervManquantAffect: intervBesoin - intervAffectation,
            avancemmentPctSelection: Math.round(avancemmentPctTempSelect),
            avancemmentPctAffectation: Math.round(avancemmentPctTempAffect),
            submitted_at: datas[i].submitted_at,
            validated_at: datas[i].validated_at,
            postes: postesBesoin
        })
        if (intervBesoin === intervSelection) {
            epreuvesCompleteSelection++
        }
        if (intervBesoin === intervAffectation) {
            epreuvesCompleteAffectation++
        }
        intervBesoinGlobal += intervBesoin
        intervSelectionGlobal += intervSelection
        intervAffectationGlobal += intervAffectation
        if (datas[i].validated_at) {
            pvSigne++
        }
    }
    const postesBesoinGlobal = []
    for (const po in objPostesBesoinGlobal) {
        postesBesoinGlobal.push(objPostesBesoinGlobal[po])
    }

    const intervBesoinSelectionGlobalTemp =
        intervBesoinGlobal > 0 ? (intervSelectionGlobal / intervBesoinGlobal) * 100 : 0
    const intervBesoinAffectationGlobalTemp =
        intervBesoinGlobal > 0 ? (intervAffectationGlobal / intervBesoinGlobal) * 100 : 0
    commit('SET_INTERVENANTS_SELECTION_MANQUANTS', intervBesoinGlobal - intervSelectionGlobal)
    commit('SET_INTERVENANTS_AFFECTATION_MANQUANTS', intervBesoinGlobal - intervAffectationGlobal)
    commit('SET_AVANCEMMENT_SELECTION_PCT', Math.round(intervBesoinSelectionGlobalTemp))
    commit('SET_AVANCEMMENT_AFFECTATION_PCT', Math.round(intervBesoinAffectationGlobalTemp))
    commit('SET_POSTESPV', postesBesoinGlobal)
    commit('SET_EPREUVES_COMPLETES_SELECTION', epreuvesCompleteSelection)
    commit('SET_EPREUVES_COMPLETES_AFFECTATION', epreuvesCompleteAffectation)
    commit('SET_PVS', datasTemp)
    commit('SET_PVS_SIGNES', pvSigne)
    // commit('SET_CURRENT_PAGE', response.data.meta.current_page)
    // commit('SET_LAST_PAGE', response.data.meta.last_page)
    // commit('SET_TOTAL_PAGE', response.data.data.length)
}

export const state: StatePvs = {
    pvs: [],
    loading: false,
    error: null,
    meta: null,
    links: null,
    totalRows: 0,
    pvSelect: null,
    besoins: [],
    source_pv_pdf: '',
    postesPv: [],
    intervSelectionManquant: 0,
    intervAffectationManquant: 0,
    avancemmentSelectionPct: 0,
    avancemmentAffectationPct: 0,
    epreuvesCompletesSelection: 0,
    epreuvesCompletesAffectation: 0,
    pvSigne: 0,
    besoinOptions: null,
    postesPvCourant: null,
    bloc_insertion: '',
    reconduction_sans_decision: 0,
    nouvelle_candidature_sans_decision: 0,
    currentPage: 0,
    lastPage: 0,
    totalPage: 0
}

export const mutations = {
    RESET_STATE(state: StatePvs) {
        Object.assign(state, {
            pvs: [],
            loading: false,
            error: null,
            meta: null,
            links: null,
            totalRows: 0,
            pvSelect: null,
            besoins: [],
            source_pv_pdf: '',
            postesPv: [],
            intervSelectionManquant: 0,
            intervAffectationManquant: 0,
            avancemmentSelectionPct: 0,
            avancemmentAffectationPct: 0,
            epreuvesCompletesSelection: 0,
            epreuvesCompletesAffectation: 0,
            pvSigne: 0,
            besoinOptions: null,
            postesPvCourant: null,
            bloc_insertion: '',
            reconduction_sans_decision: 0,
            nouvelle_candidature_sans_decision: 0,
            currentPage: 0,
            lastPage: 0,
            totalPage: 0
        })
    },
    SET_INTERVENANTS_SELECTION_MANQUANTS(state: StatePvs, value: number) {
        state.intervSelectionManquant = value
    },
    SET_INTERVENANTS_AFFECTATION_MANQUANTS(state: StatePvs, value: number) {
        state.intervAffectationManquant = value
    },
    SET_AVANCEMMENT_SELECTION_PCT(state: StatePvs, value: number) {
        state.avancemmentSelectionPct = value
    },
    SET_AVANCEMMENT_AFFECTATION_PCT(state: StatePvs, value: number) {
        state.avancemmentAffectationPct = value
    },
    SET_PVS(state: StatePvs, pvs: Array<PvInterface>) {
        state.pvs = pvs
    },
    SET_POSTESPV(state: StatePvs, postes: Array<any>) {
        state.postesPv = postes
    },
    SET_PV_SELECT(state: StatePvs, pv: PvInterface) {
        state.pvSelect = pv
    },
    SET_BESOINS(state: StatePvs, besoins: Array<any>) {
        state.besoins = besoins
    },
    SET_POSTES_PV_COURANT(state: StatePvs, poste: any) {
        state.postesPvCourant = poste
    },
    SET_BESOIN_OPTIONS(state: StatePvs, options: any) {
        state.besoinOptions = options
    },
    SET_PVS_SIGNES(state: StatePvs, value: number) {
        state.pvSigne = value
    },
    SET_EPREUVES_COMPLETES_SELECTION(state: StatePvs, value: number) {
        state.epreuvesCompletesSelection = value
    },
    SET_EPREUVES_COMPLETES_AFFECTATION(state: StatePvs, value: number) {
        state.epreuvesCompletesAffectation = value
    },
    SET_LOADING(state: StatePvs, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StatePvs, error: Array<any>) {
        state.error = error
    },
    SET_META(state: StatePvs, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StatePvs, links: Array<any>) {
        state.links = links
    },
    SET_TOTALROWS(state: StatePvs, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_SOURCE_PDF(state: StatePvs, source: string) {
        state.source_pv_pdf = source
    },
    SET_BLOC_CALENDRIER(state: StatePvs, content: string) {
        state.bloc_insertion = content
    },
    SET_BLOC_NOUVELLE_CANDIDATURE(state: StatePvs, content: string) {
        state.bloc_insertion = content
    },
    SET_BLOC_NON_RECONDUCTION(state: StatePvs, content: string) {
        state.bloc_insertion = content
    },
    SET_BLOC_BESOIN(state: StatePvs, content: string) {
        state.bloc_insertion = content
    },
    SET_BLOC_ANNEXE(state: StatePvs, content: string) {
        state.bloc_insertion = content
    },
    SET_RECONDUCTIONS_SANS_DECISION(state: StatePvs, dossiers: Array<DossierSelectionInterface>) {
        const dossiers_filtres = dossiers.filter(
            (d: DossierSelectionInterface) =>
                (d.courant && d.courant.decision_selection === Decision.DECISION_AUCUNE) || d.courant === null
        )
        state.reconduction_sans_decision = dossiers_filtres.length
    },
    SET_NOUVELLE_CANDIDATURE_SANS_DECISION(state: StatePvs, dossiers: Array<DossierSelectionInterface>) {
        const dossiers_filtres = dossiers.filter(
            (d: DossierSelectionInterface) =>
                (d.courant && d.courant.decision_selection === Decision.DECISION_AUCUNE) || d.courant === null
        )
        state.nouvelle_candidature_sans_decision = dossiers_filtres.length
    },
    UPDATE_TOTAUX_BESOIN(state: StatePvs) {
        const postePvCourantTemp: {
            [posteLibelle: string]: {
                selection: number,
                affectation: number,
                besoin: number,
                selectionManquant: number,
                affectationManquant: number,
                precedent: number,
                regroupement: string,
                name_plural: string
            }
        } = {}
        for (let i = 0; i < state.besoins.length; i++) {
            if (!postePvCourantTemp[state.besoins[i].poste.regroupement]) {
                postePvCourantTemp[state.besoins[i].poste.regroupement] = {
                    selection: 0,
                    affectation: 0,
                    besoin: 0,
                    selectionManquant: 0,
                    affectationManquant: 0,
                    precedent: 0,
                    regroupement: state.besoins[i].poste.regroupement,
                    name_plural: state.besoins[i].poste.name_plural
                }
            }
            postePvCourantTemp[state.besoins[i].poste.regroupement].selection += state.besoins[i].intervenants.selection
            postePvCourantTemp[state.besoins[i].poste.regroupement].affectation +=
                state.besoins[i].intervenants.affectation
            postePvCourantTemp[state.besoins[i].poste.regroupement].besoin += state.besoins[i].intervenants.besoin
            postePvCourantTemp[state.besoins[i].poste.regroupement].precedent += state.besoins[i].intervenants.precedent
            postePvCourantTemp[state.besoins[i].poste.regroupement].selectionManquant =
                postePvCourantTemp[state.besoins[i].poste.regroupement].besoin -
                postePvCourantTemp[state.besoins[i].poste.regroupement].selection
            postePvCourantTemp[state.besoins[i].poste.regroupement].affectationManquant =
                postePvCourantTemp[state.besoins[i].poste.regroupement].besoin -
                postePvCourantTemp[state.besoins[i].poste.regroupement].affectation
        }
        state.postesPvCourant = postePvCourantTemp
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    }
}

export const actions = {
    getPvs({ commit }: { commit: any }, postes: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getPvs()
                .then((response) => {
                    setPvs(commit, response, postes)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPv({ commit }: { commit: any }, pv_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getPv(pv_id)
                .then((response) => {
                    commit('SET_PV_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    getBesoins(commit, pv_id).then(() => resolve(response))
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updatePv({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.updatePv(params.pv_id, params.datas)
                .then((response) => {
                    resolve(response)
                    commit('SET_PV_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBesoins({ commit }: { commit: any }, pv_id: number) {
        getBesoins(commit, pv_id)
    },
    getPDF({ commit }: { commit: any }, pv_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getPDF(pv_id)
                .then((response) => {
                    commit('SET_SOURCE_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateBesoin({ commit }: { commit: any }, params: any) {
        commit('UPDATE_TOTAUX_BESOIN')
        return new Promise((resolve, reject) => {
            PvService.updateBesoin(params.pv_id, params.poste_id, params.entity_id, params.besoin)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBlocCalendrier({ commit }: { commit: any }, pv_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getBlocCalendrier(pv_id)
                .then((response) => {
                    resolve(response)
                    commit('SET_BLOC_CALENDRIER', response.data)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBlocNouvelleCandidature({ commit }: { commit: any }, pv_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getBlocNouvelleCandidature(pv_id)
                .then((response) => {
                    commit('SET_BLOC_NOUVELLE_CANDIDATURE', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBlocNonReconduction({ commit }: { commit: any }, pv_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getBlocNonReconduction(pv_id)
                .then((response) => {
                    commit('SET_BLOC_NON_RECONDUCTION', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBlocBesoin({ commit }: { commit: any }, pv_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getBlocBesoin(pv_id)
                .then((response) => {
                    commit('SET_BLOC_BESOIN', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBlocAnnexe({ commit }: { commit: any }, pv_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PvService.getBlocAnnexe(pv_id)
                .then((response) => {
                    commit('SET_BLOC_ANNEXE', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    loading: (state: StatePvs) => {
        return state.loading
    },
    pvs: (state: StatePvs) => {
        return state.pvs
    },
    pvSelect: (state: StatePvs) => {
        return state.pvSelect
    },
    besoins: (state: StatePvs) => {
        return state.besoins
    },
    postesPvCourant(state: StatePvs) {
        return state.postesPvCourant
    },
    besoinOptions: (state: StatePvs) => {
        return state.besoinOptions
    },
    error: (state: StatePvs) => {
        return state.error
    },
    meta: (state: StatePvs) => {
        return state.meta
    },
    links: (state: StatePvs) => {
        return state.links
    },
    totalRows: (state: StatePvs) => {
        return state.pvs.length
    },
    source_pv_pdf: (state: StatePvs) => {
        return state.source_pv_pdf
    },
    intervSelectionManquant: (state: StatePvs) => {
        return state.intervSelectionManquant
    },
    avancemmentSelectionPct: (state: StatePvs) => {
        return state.avancemmentSelectionPct
    },
    epreuvesCompletesSelection: (state: StatePvs) => {
        return state.epreuvesCompletesSelection
    },
    intervAffectationManquant: (state: StatePvs) => {
        return state.intervAffectationManquant
    },
    avancemmentAffectationPct: (state: StatePvs) => {
        return state.avancemmentAffectationPct
    },
    epreuvesCompletesAffectation: (state: StatePvs) => {
        return state.epreuvesCompletesAffectation
    },
    pvSigne: (state: StatePvs) => {
        return state.pvSigne
    },
    postesPv: (state: StatePvs) => {
        return state.postesPv
    },
    bloc_insertion: (state: StatePvs) => {
        return state.bloc_insertion
    },
    reconduction_sans_decision: (state: StatePvs) => {
        return state.reconduction_sans_decision
    },
    nouvelle_candidature_sans_decision: (state: StatePvs) => {
        return state.nouvelle_candidature_sans_decision
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        if (state.users) {
            return state.users.length
        } else {
            return 0
        }
    }
}
